import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import elBioURApp from './elBioURApp';
//import reportWebVitals from './reportWebVitals';

const target = document.getElementById('elbio_user_report_root');
if (target) { ReactDOM.render(<elBioURApp/>, target); }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
//reportWebVitals(console.log);
